import React from 'react';

import Section from 'components/Section';

import Facebook from '../../assets/main/facebook.svg';
import Instagram from '../../assets/main/instagram.svg';
import Tiktok from '../../assets/main/tiktok.svg';

const Social = () => (
  <Section>
    <main className="px-4 sm:px-6 lg:px-8">
      <h1 className="mx-auto mb-20 text-4xl tracking-tight font-bold text-gray-700 sm:text-3xl md:text-4xl text-center">
        <span className="block lg:inline">Follow Us:</span>
      </h1>
      <div className="text-center">
        <button className="h-12 w-12 mr-12" type="button">
          <a href="https://www.facebook.com/KopiRunApp/">
            <img alt="KopiRun Facebook" src={Facebook} />
          </a>
        </button>
        <button className="h-12 w-12 mr-12" type="button">
          <a href="https://www.instagram.com/kopirunapp/">
            <img alt="KopiRun Instagram" src={Instagram} />
          </a>
        </button>
        <button className="h-12 w-12" type="button">
          <a href="https://www.tiktok.com/@kopirunapp">
            <img alt="KopiRun Tiktok" src={Tiktok} />
          </a>
        </button>
      </div>
    </main>
  </Section>
);

export default Social;
